import { MockInteractive } from '@/parts/mock/interactive/component'
import JesterInteractive from '@/parts/jester/interactive/component'
import type { ContentActions } from '@blakeelearning/content-loader-render'
import type { ContentSpec } from '@blakeelearning/content-loader-render'
import type { InteractiveProps } from '@/parts/jester/interactive/types'
import { useDebuggableContext } from '../wrapper/component'
import { memo } from 'react'
import { set } from 'radash'

export type Interactive<S extends ContentSpec, A extends ContentActions<S>> = (
  props: InteractiveProps<S, A>,
) => JSX.Element

export type DebuggableInteractiveProps<S extends ContentSpec, A extends ContentActions<S>> = InteractiveProps<S, A> & {
  mock?: Interactive<S, A>
  real?: Interactive<S, A>
}

export default memo(function DebuggableInteractive<S extends ContentSpec, A extends ContentActions<S>>({
  configuration,
  mock: Mock = MockInteractive,
  real: Real = JesterInteractive,
}: DebuggableInteractiveProps<S, A>) {
  const context = useDebuggableContext()
  const isContentDebugMode = context.isContentDebugMode
  const updatedFeatures = Array.isArray(configuration.variables?.features)
    ? [...configuration.variables?.features, 'debugModeFF']
    : ['debugModeFF']
  const Interactive = context.isMockingInteractive ? Mock : Real
  return configuration ? (
    <Interactive
      configuration={isContentDebugMode ? set(configuration, 'variables.features', updatedFeatures) : configuration}
    />
  ) : null
})
