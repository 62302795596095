import type { GameVariables } from '@/parts/games/interactive'
import type { InteractiveProps } from '@/parts/jester/interactive/types'
import { isManifestIndex } from '@/parts/jester/manifests/types'
import { toReadable } from '@/utils/readable'
import type { GameContentActions, GameContentSpec } from '@blakeelearning/content-specs-mathseeds-prime'
import { useState } from 'react'

export default function MockGame({
  configuration: {
    manifests: [manifest],
    contentActions: { goBack, enterGame, saveGame },
    variables: {
      gameLevels,
      studentData: { coinCount },
      gamePrice,
    },
  },
}: InteractiveProps<GameContentSpec, GameContentActions, GameVariables>) {
  if (!manifest || !isManifestIndex(manifest)) throw new Error('Could not recognise this manifest type.')

  const [gameId] = manifest
  const gameLevel = gameLevels.find(({ gameId }) => gameId === gameId)
  const [level, setLevel] = useState(gameLevel?.level ?? 0)

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="flex flex-col gap-3">
        <div className="flex flex-col justify-center items-center">
          <span data-testid="game-name" className="font-bold text-xl uppercase">
            {toReadable(gameId)}
          </span>
          <span data-testid="coin-count">{coinCount} coins</span>
          <span data-testid="game-price">Game price: {gamePrice} coins</span>
        </div>
        <div className="flex">
          <button
            type="button"
            className="rounded bg-emerald-700 text-white p-4 grow"
            data-testid="enter-game"
            onClick={() => enterGame({ gameId })}
          >
            Enter Game
          </button>
        </div>
        <div className="flex flex-row gap-2">
          <button
            type="button"
            className="rounded bg-emerald-700 text-white p-4 grow"
            data-testid="save-game"
            onClick={() => saveGame({ gameId, level })}
          >
            Save Game
          </button>
          <input
            type="number"
            min="0"
            value={level}
            onInput={(e) => setLevel(parseInt((e.target as HTMLInputElement).value, 10))}
            className="p-2 outline-none text-center text-xl"
            data-testid="level"
          />
        </div>
        <div className="flex">
          <button
            type="button"
            className="rounded bg-emerald-700 text-white p-4 grow"
            data-testid="go-back"
            onClick={() => goBack()}
          >
            Go back
          </button>
        </div>
      </div>
    </div>
  )
}
