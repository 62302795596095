import type { FinishedContentActions, FinishedContentSpec } from '@blakeelearning/content-specs-mathseeds-prime'
import type { InteractiveProps } from '@/parts/jester/interactive/types'

export default function MockFinished({
  configuration: {
    contentActions: { goToLessonTrees, goToMathseeds },
  },
}: InteractiveProps<FinishedContentSpec, FinishedContentActions>) {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="flex flex-col gap-3 items-center">
        <span className="font-bold text-6xl">Well done!</span>
        <div className="flex flex-col gap-3 text-4xl">
          <button
            data-testid="goto-lessons"
            className="rounded bg-emerald-700 text-white p-4 grow flex flex-col items-center uppercase"
            type="button"
            onClick={goToLessonTrees}
          >
            <span className="text-sm">Back to</span>
            <span className="font-bold">Lessons</span>
          </button>
          <button
            data-testid="goto-mathseeds"
            className="rounded bg-emerald-700 text-white p-4 grow flex flex-col items-center uppercase"
            type="button"
            onClick={goToMathseeds}
          >
            <span className="text-sm">Go to</span>
            <span className="font-bold">Mathseeds</span>
          </button>
        </div>
      </div>
    </div>
  )
}
