import type { InteractiveProps } from './types'
import { memo, useEffect, useRef } from 'react'
import type {
  ContentActions,
  ContentRenderOptions,
  ContentSpec,
  JesterConfig,
} from '@blakeelearning/content-loader-render'
import { renderJester } from '@blakeelearning/content-loader-render'
import { Logger, useLogger } from '@/parts/logger/hook'
import { useConfiguration } from '@/parts/configuration/wrapper'

function JesterInteractive<V, S extends ContentSpec, A extends ContentActions<S>>({
  configuration,
}: InteractiveProps<S, A, V>) {
  const { interactiveContent } = useConfiguration()

  return (
    <RenderedInteractive
      configuration={configuration}
      renderer={renderJester}
      logger={useLogger()}
      jesterConfiguration={interactiveContent}
    />
  )
}

export function RenderedInteractive<V, S extends ContentSpec, A extends ContentActions<S>>({
  configuration: { accent, features = [], ...rest },
  renderer,
  logger,
  jesterConfiguration,
}: InteractiveProps<S, A, V> & { renderer: typeof renderJester; logger: Logger; jesterConfiguration: JesterConfig }) {
  const containerElement = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const options = {
      features: [...features, 'jesterSelfResizeFF', 'jesterFullscreenFF'],
      onError: logger.error,
      ...rest,
    } as ContentRenderOptions<S, A>

    if (!containerElement.current) throw new Error('There is no container element')
    return renderer(containerElement.current, accent, jesterConfiguration, options)
  }, [accent, features, rest, jesterConfiguration, logger, renderer])

  return (
    <div
      data-testid="jester-interactive"
      className="absolute inset-x-0 inset-y-0 flex justify-center items-center"
      ref={containerElement}
    />
  )
}

export default memo(JesterInteractive) as typeof JesterInteractive
