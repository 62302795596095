import type { Activity } from '@/graphql/types'
import { createContext, useContext } from 'react'

export const ActivitiesContext = createContext<Activity[] | undefined>(undefined)

export function useActivities() {
  const context = useContext(ActivitiesContext)
  if (!context) throw new Error('No activities context has been defined.')
  return context
}
