import { MaybeActivityManifestBundle, isManifestIndex } from '@/parts/jester/manifests/types'

export const toReadable = (kebabCaseText: string) =>
  kebabCaseText.replace(/-/g, ' ').replace(/\b(\w)/g, (c) => c.toUpperCase())

export const extractFirstManifestKey = (manifests: MaybeActivityManifestBundle[]) => {
  const [manifest] = manifests
  if (!manifest || !isManifestIndex(manifest)) throw new Error('Could not recognise this manifest type.')
  const [key] = manifest
  return key
}
