import type { InteractiveProps } from '@/parts/jester/interactive/types'
import type { MainNavigationVariables } from '@/parts/main-navigation/interactive'
import type { ContentActions } from '@blakeelearning/content-loader-render'
import type { IndexContentSpec } from '@blakeelearning/content-specs-mathseeds-prime'

export default function MockMainNavigation({
  configuration,
}: InteractiveProps<IndexContentSpec, ContentActions<IndexContentSpec>, MainNavigationVariables>) {
  const { contentActions, variables } = configuration
  const { studentData } = variables as MainNavigationVariables

  return (
    <div className="flex flex-col h-screen justify-center items-center text-center">
      <h1 className="uppercase text-2xl">Mathseeds Prime</h1>
      <div>
        <span data-testid="student-name" className="block text-xl">
          Hi {studentData.studentName}!
        </span>
        <span data-testid="coin-count" className="block text-lg">
          {studentData.coinCount} coins
        </span>
      </div>
      <div className="flex flex-wrap justify-center w-1/3 gap-2 m-4">
        <button
          data-testid="games-link"
          onClick={contentActions.goToGames}
          className="rounded basis-5/12 grow py-6 text-2xl bg-white/50 flex flex-col items-center justify-center hover:bg-white/100"
        >
          Games
        </button>
        <button
          data-testid="lesson-trees-link"
          onClick={contentActions.goToLessonTrees}
          className="rounded basis-5/12 grow py-6 text-2xl bg-white/50 flex flex-col items-center justify-center hover:bg-white/100"
        >
          Lesson Trees
        </button>
        <button
          data-testid="shop-link"
          onClick={contentActions.goToShop}
          className="rounded basis-5/12 grow py-6 text-2xl bg-white/50 flex flex-col items-center justify-center hover:bg-white/100"
        >
          Shop
        </button>
        <button
          data-testid="log-out-link"
          onClick={contentActions.logOut}
          className="rounded basis-5/12 grow py-6 text-2xl bg-white/50 flex flex-col items-center justify-center hover:bg-white/100"
        >
          Logout
        </button>
      </div>
    </div>
  )
}
