import type { InteractiveProps } from '@/parts/jester/interactive/types'
import type {
  UnitContentActions,
  UnitContentSpec,
} from '@blakeelearning/content-specs-mathseeds-prime'
import { useState } from 'react'
import { group } from 'radash'
import type { UnitInteractiveVariables } from '@/parts/unit/interactive'
import { useProgressManager } from '@/parts/progression/manager'
import type { ThreadId } from '@/graphql/types'

export default function MockLessonMenu({
  configuration: {
    contentActions: { goBack, goToActivity, goToFascinatingFact, goToUnit, openedSeedbob },
    variables: {
      lessonData: { activityData, initialUnit, studentProgress },
      studentData: { avatar: _avatar, coinCount },
      unopenedSeedbob,
    },
  },
}: InteractiveProps<UnitContentSpec, UnitContentActions, UnitInteractiveVariables>) {
  const { isLevelAccessible } = useProgressManager()
  const [unitId, setUnitId] = useState(initialUnit)
  const threadId = studentProgress.thread as ThreadId
  const unitData = activityData.find((unit) => unit.unitId === unitId)
  const availableUnitIds = activityData.map((unit) => unit.unitId)
  const lessonActivities = group(unitData?.activities ?? [], ({ lessonName }) => lessonName)

  const prevUnitId = unitId - 1
  const nextUnitId = unitId + 1

  const isUnitAccessible = (unitId: number) => {
    const level = { ...studentProgress, threadId, unitId }
    const [isAccessible] = isLevelAccessible(level)
    return availableUnitIds.includes(unitId) && isAccessible
  }

  const updateUnitId = (unitId: number) => {
    setUnitId(unitId)
    goToUnit({ unitId })
  }

  return (
    <div className="flex justify-center items-center max-w-screen min-h-screen">
      <div className="flex w-3/4 flex-col">
        <div className="flex flex-col justify-center items-center gap-5">
          <div className="flex">
            <button className="p-4 bg-emerald-700 text-white rounded" data-test-go-back type="button" onClick={goBack}>
              Go Back
            </button>
          </div>

          <div className="w-full flex justify-between items-center">
            <button
              data-test-go-previous-unit
              type="button"
              onClick={() => updateUnitId(prevUnitId)}
              className={`p-4 bg-emerald-700 text-white rounded ${
                !isUnitAccessible(prevUnitId) && 'invisible pointer-events-none'
              }`}
            >
              Previous Unit
            </button>

            <div className="font-bold text-xl uppercase" data-testid={unitId}>
              {threadId}: unit {unitId}
            </div>

            <button
              data-test-go-next-unit
              type="button"
              onClick={() => updateUnitId(nextUnitId)}
              className={`p-4 bg-emerald-700 text-white rounded ${
                !isUnitAccessible(nextUnitId) && 'invisible pointer-events-none'
              }`}
            >
              Next Unit
            </button>
          </div>

          <div data-test-coins className="">
            <span className="font-bold uppercase">coins:</span> <span>{coinCount}</span>
          </div>

          {unopenedSeedbob && (
            <div data-testid="unopened-seedbob">
              <span>Unopened seedbob:</span>
              <span data-testid={'unopened-seedbob-' + unopenedSeedbob}>{unopenedSeedbob}</span>
            </div>
          )}
        </div>

        <div className="flex justify-center items-center">
          <button
            data-test-fascinating-fact
            type="button"
            className="rounded bg-emerald-700 text-white p-2"
            onClick={() => goToFascinatingFact({ unitId })}
          >
            Go to Fascinating Fact
          </button>
        </div>

        <div className="flex flex-col gap-5">
          {Object.entries(lessonActivities).map(([lessonName, activities = []]) => (
            <div key={lessonName} className="">
              <div className="font-bold uppercase text-emerald-900">{lessonName}</div>

              <div className="flex gap-2 flex-wrap">
                {activities.map((activity) => {
                  const [isAccessible] = isLevelAccessible(activity)

                  return (
                    <div key={activity.id} className="contents">
                      <button
                        data-testid={`go-to-activity-${activity.lessonId}-${activity.positionInLesson}`}
                        disabled={!isAccessible}
                        className={`flex flex-col justify-center items-center rounded ${
                          isAccessible ? 'bg-emerald-700 hover:bg-emerald-600' : 'bg-slate-700'
                        } text-white p-2 basis-28 break-all transition`}
                        type="button"
                        onClick={() => goToActivity({ activityId: activity.id, unitId })}
                      >
                        <span className="font-bold uppercase">{activity.positionInLesson}</span>
                        <span>{activity.activityType}</span>
                      </button>

                      {activity.seedbob && (
                        <button
                          disabled={true}
                          data-testid={`seedbob-${activity.seedbob}`}
                          className="flex flex-col justify-center items-center rounded bg-slate-700 text-white p-2 basis-28 break-all transition"
                          type="button"
                          onClick={() => activity.seedbob && openedSeedbob({ seedbobId: activity.seedbob })}
                        >
                          <span className="font-bold uppercase">Seedbob</span>
                          <span>{activity.seedbob}</span>
                        </button>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
