import { Student, ThreadSpecifier, toLowercaseThreadId } from '@/graphql/types'
import { first, flat, group, listify, lowerize, mapValues, select, unique } from 'radash'
import { useMemo } from 'react'
import { useActivitiesManager } from '../activities/manager'
import type { ActivitiesOverview, GradeExtractor } from '../activities/types'
import { useStudent } from '../student/hook'
import type { AccessStatus, PartialProgressLevel, ProgressOverview, EnrichedProgress, ProgressLevel } from './types'

function extractProgressOverview(levels: EnrichedProgress[]): ProgressOverview {
  const groupedByThread = group(levels, (l) => l.threadId)
  const withLowercaseKeys = lowerize(groupedByThread)
  return mapValues(withLowercaseKeys, (activities = []) => {
    const unitAndGradeSpecifiers = activities.map(({ unitId, gradeId }) => ({ unitId, gradeId }))
    return first(unitAndGradeSpecifiers) ?? undefined
  })
}

function isThreadLevelAccessible(
  { threadId, unitId, lessonId, positionInLesson }: PartialProgressLevel,
  progress: Omit<ProgressLevel, 'threadId'>,
): AccessStatus {
  if (!unitId || unitId < progress.unitId) return [true]
  if (unitId > progress.unitId)
    return [false, `Unit ${unitId} is beyond the student\'s current progress for thread ${threadId}.`]
  if (!lessonId || lessonId < progress.lessonId) return [true]
  if (lessonId > progress.lessonId)
    return [
      false,
      `Lesson ${lessonId} is beyond the student\'s current progress for thread ${threadId}, unit ${unitId}.`,
    ]
  if (!positionInLesson || positionInLesson <= progress.positionInLesson) return [true]
  return [
    false,
    `Position ${positionInLesson} is beyond the student\'s current progress for thread ${threadId}, unit ${unitId}, lesson ${lessonId}.`,
  ]
}

export function getProgressManager(levels: EnrichedProgress[], activitiesOverview: ActivitiesOverview) {
  const getProgressFor = ({ threadId }: ThreadSpecifier) => levels.find((p) => p.threadId === threadId)
  const availableGradeIdsPerThread = mapValues(activitiesOverview, (grades, threadId) => {
    const unitsGroupedByGrade = grades.map(({ gradeId, units }) => units.map(({ unitId }) => ({ gradeId, unitId })))
    const units = flat(unitsGroupedByGrade)
    const minimumGradeId = Math.min(...units.map(({ gradeId }) => gradeId))
    const accessibleGrades = select(
      units,
      ({ gradeId }) => gradeId,
      ({ unitId }) => {
        const progress = getProgressFor({ threadId })
        return progress !== undefined && unitId <= progress.unitId
      },
    )
    return unique([minimumGradeId, ...accessibleGrades])
  })

  const maximumGradePerThread = mapValues(availableGradeIdsPerThread, (grades) => Math.max(...grades))
  const currentGradeId = Math.min(...Object.values(maximumGradePerThread))
  const availableGradeIds = unique(flat(listify(availableGradeIdsPerThread, (_, grades) => grades)))

  return {
    overview: extractProgressOverview(levels),
    currentGradeId,
    getProgressFor,
    getCurrentUnitFor: (spec: ThreadSpecifier) => getProgressFor(spec)?.unitId,
    isGradeAccessible: (gradeId: number) => availableGradeIds.includes(gradeId),
    isLevelAccessible: (level: PartialProgressLevel) => {
      const progress = getProgressFor({ threadId: toLowercaseThreadId(level.threadId) })
      if (!progress) throw new Error(`There is no progress for thread ${level.threadId}.`)
      return isThreadLevelAccessible(level, progress)
    },
  }
}

export function enrichProgressWithGrades(
  { progress: levels, rewardTotals: { seedbobs } }: Student,
  extractGrade: GradeExtractor,
): EnrichedProgress[] {
  return levels.map(({ threadId, unitId, ...rest }) => {
    const thread = toLowercaseThreadId(threadId)
    return {
      ...rest,
      unitId,
      threadId: thread,
      thread,
      gradeId: extractGrade({ threadId: thread, unitId }),
      seedbobs,
    }
  })
}

export function useProgressManager() {
  const student = useStudent()
  const { getGradeFor, overview } = useActivitiesManager()
  const enrichedProgress = enrichProgressWithGrades(student, getGradeFor)
  return useMemo(() => getProgressManager(enrichedProgress, overview), [enrichedProgress, overview])
}
