import { Activity, LowercaseThreadId, toContentReadableActivity, toLowercaseThreadId } from '@/graphql/types'
import { useMemo } from 'react'
import type {
  ActivitiesOverview,
  GradeExtractor,
  ThreadAndUnitKey,
  UnitDescriptors,
  UnitKey,
  UnitSpecifier,
} from './types'
import { first, group, listify, lowerize, mapValues, unique } from 'radash'
import { useActivities } from './hook'

const toThreadUnitKey = ({ threadId, unitId }: UnitSpecifier): ThreadAndUnitKey => `${threadId}:${unitId}`
const toUnitKey = ({ unitId, unitName }: UnitDescriptors): UnitKey => `${unitId}:${unitName}`
const fromUnitKey = (key: UnitKey) => {
  const [unitId, unitName] = key.split(':') as [string, string]
  return { unitId: Number(unitId), unitName }
}

function getUnitLookupsFor(activities: Activity[]) {
  const unitLookup = group(activities, ({ threadId, unitId }) =>
    toThreadUnitKey({ threadId: toLowercaseThreadId(threadId), unitId }),
  )

  return {
    getActivitiesForUnit(spec: UnitSpecifier) {
      return unitLookup[toThreadUnitKey(spec)] ?? []
    },
    getGradeFor(spec: UnitSpecifier) {
      const lookup = mapValues(unitLookup, (activities = []) => {
        const gradeIds = activities.map(({ gradeId }) => gradeId)
        return first(gradeIds) ?? undefined
      })
      return lookup[toThreadUnitKey(spec)]
    },
  }
}

function generateUnitOverviewDirectory(activities: Activity[]) {
  const groupedByThread = group(activities, ({ threadId }) => threadId)
  const groupedByLowercaseThread = lowerize(groupedByThread)

  return mapValues(groupedByLowercaseThread, (activities = []) => {
    const groupedByGrade = group(activities, ({ gradeId }) => gradeId)

    return mapValues(groupedByGrade, (activities = []) => {
      const groupedByUnit = group(activities, toUnitKey)
      return listify(groupedByUnit, (key, activities = []) => ({
        ...fromUnitKey(key),
        activities: activities.map(toContentReadableActivity),
      }))
    })
  })
}

function getUnitOverviewLookupFor(activities: Activity[], getGradeFor: GradeExtractor) {
  const directory = generateUnitOverviewDirectory(activities)

  return function ({ threadId, unitId }: UnitSpecifier) {
    const gradeId = getGradeFor({ threadId, unitId })
    if (!gradeId) throw new Error(`Could not find a grade for thread ${threadId} and unit ${unitId}.`)
    return directory[threadId][gradeId]
  }
}

function toActivitiesOverview(activities: Activity[]): ActivitiesOverview {
  const groupedByThread = group(activities, ({ threadId }) => threadId)
  const groupedByLowercaseThread = lowerize(groupedByThread)

  return mapValues(groupedByLowercaseThread, (activities = []) => {
    const groupedByGrade = group(activities, ({ gradeId }) => gradeId)

    const withUnitData = mapValues(groupedByGrade, (activities = []) => {
      const toUnitSpecifiers = activities.map(({ unitId, unitName }) => ({ unitId, unitName }))
      return unique(toUnitSpecifiers, ({ unitId }) => unitId)
    })

    return listify(withUnitData, (gradeId, units) => ({ gradeId: Number(gradeId), units }))
  })
}

export function compareLevels({ unitId, lessonId, positionInLesson }: Activity, other: Activity) {
  if (unitId !== other.unitId) return unitId - other.unitId
  if (lessonId !== other.lessonId) return lessonId - other.lessonId
  if (positionInLesson !== other.positionInLesson) return positionInLesson - other.positionInLesson
  return 0
}

function getThreadLookupsFor(activities: Activity[]) {
  const groupedByThread = group(activities, ({ threadId }) => threadId)
  const groupedByLowercaseThread = lowerize(groupedByThread)
  const lookup = mapValues(groupedByLowercaseThread, (activities = []) => [...activities].sort(compareLevels))

  return {
    getActivitiesForThread: (threadId: LowercaseThreadId) => {
      return lookup[threadId]
    },
  }
}

export function getActivityManager(activities: Activity[]) {
  const { getGradeFor, getActivitiesForUnit } = getUnitLookupsFor(activities)
  const { getActivitiesForThread } = getThreadLookupsFor(activities)

  return {
    overview: toActivitiesOverview(activities),
    getGradeFor,
    getActivitiesForUnit,
    getActivitiesForThread,
    getActivity: (activityId: string) => activities.find(({ id }) => id === activityId),
    getUnitOverview: getUnitOverviewLookupFor(activities, getGradeFor),
    isUnitAvailable: (spec: UnitSpecifier) => getActivitiesForUnit(spec).length > 0,
  }
}

export function useActivitiesManager() {
  const activities = useActivities()
  return useMemo(() => getActivityManager(activities), [activities])
}
