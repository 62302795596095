import type { InteractiveProps } from '@/parts/jester/interactive/types'
import type { ContentActions } from '@blakeelearning/content-loader-render'
import type { ContentSpec } from '@blakeelearning/content-loader-render'
import { useUiState } from '@/parts/loading/hook'
import { useEffect } from 'react'
import { ComponentReference, useMockComponent } from '../hook'
import MockComponents from '../components'

export type MockInteractiveProps<S extends ContentSpec, A extends ContentActions<S>> = InteractiveProps<S, A> & {
  references: ComponentReference[]
  afterFirstRender: () => void
}

/** Loads a mock component, using information from the interactive content's configuration
 *  to determine the most appropriate equivalent for the real interactive.
 */
export function MockInteractive<S extends ContentSpec, A extends ContentActions<S>>({
  configuration,
}: InteractiveProps<S, A>) {
  const { setUiToReady } = useUiState()
  return (
    <MockInteractiveFor configuration={configuration} afterFirstRender={setUiToReady} references={MockComponents} />
  )
}

export function MockInteractiveFor<S extends ContentSpec, A extends ContentActions<S>>(
  props: MockInteractiveProps<S, A>,
) {
  const {
    configuration: {
      manifests: [manifest],
    },
    references,
    afterFirstRender = () => {},
  } = props
  useEffect(afterFirstRender, [afterFirstRender])
  if (!manifest) throw new Error(`No manifest was provided.`)
  const Component = useMockComponent(manifest, references)
  return <Component {...props} />
}
