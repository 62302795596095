import type { Interactive } from '../jester/interactive/types'
import { ManifestIndex, MaybeActivityManifestBundle, isManifestIndex } from '../jester/manifests/types'

export type ComponentReference = {
  url?: string | RegExp
  key?: string | RegExp
  component: Interactive
}

export function findComponent([key, url]: ManifestIndex, references: ComponentReference[]) {
  return references.find((r) => (!r.url || url.toString().match(r.url)) && (!r.key || key.match(r.key)))?.component
}

export function useMockComponent(manifest: MaybeActivityManifestBundle, references: ComponentReference[]) {
  if (!isManifestIndex(manifest)) throw new Error(`Manifest "${manifest}" cannot be used for mocking.`)
  const Component = findComponent(manifest, references)
  if (!Component) throw new Error(`Unable to find component for "${manifest}".`)
  return Component
}
