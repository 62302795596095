import type { InteractiveProps } from '@/parts/jester/interactive/types'
import { extractFirstManifestKey, toReadable } from '@/utils/readable'
import type {
  FascinatingFactContentActions,
  FascinatingFactContentSpec,
} from '@blakeelearning/content-specs-mathseeds-prime'

export default function MockFascinatingFact({
  configuration: {
    contentActions: { goBack },
    manifests,
  },
}: InteractiveProps<FascinatingFactContentSpec, FascinatingFactContentActions>) {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="flex flex-col gap-3 items-center">
        <span data-testid="activity-name" className="font-bold uppercase text-xl">
          {toReadable(extractFirstManifestKey(manifests))}
        </span>
        <button
          data-testid="go-back"
          className="rounded bg-emerald-700 text-white p-4 grow"
          type="button"
          onClick={goBack}
        >
          Go Back
        </button>
      </div>
    </div>
  )
}
