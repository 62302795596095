import type { GameMenuVariables } from '@/parts/games/menu/interactive'
import type { InteractiveProps } from '@/parts/jester/interactive/types'
import { toReadable } from '@/utils/readable'
import type {
  GamesMenuContentActions,
  GamesMenuContentSpec,
} from '@blakeelearning/content-specs-mathseeds-prime'

const gameIds = ['guess-my-number', 'on-target', 'tikku-tikku', 'matchstick-shapes', 'matchstick-numbers', 'sudoku']

export default function MockGamesMenu({ ...props }: InteractiveProps<GamesMenuContentSpec, GamesMenuContentActions>) {
  return <MockGamesMenuFor {...props} gameIds={gameIds} />
}

export function MockGamesMenuFor({
  configuration: {
    contentActions: { goBack, playGame },
    variables: { gamePrice },
  },
  gameIds,
}: InteractiveProps<GamesMenuContentSpec, GamesMenuContentActions, GameMenuVariables> & {
  gameIds: string[]
}) {
  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <span data-testid="game-price">Game price: {gamePrice} coins</span>
      </div>
      <div data-testid="games" className="flex flex-row flex-wrap w-1/2 my-2 justify-center items-center">
        {gameIds.map((id) => (
          <button
            key={id}
            type="button"
            data-testid={'play-game-' + id}
            className="border rounded border-black p-2 mx-2 my-2 w-1/4"
            onClick={() => playGame({ id })}
          >
            {toReadable(id)}
          </button>
        ))}
      </div>
      <div className="flex flex-row flex-wrap w-1/2 my-2 justify-center items-center">
        <button type="button" data-testid="go-back" className="border rounded border-black p-2 mx-2" onClick={goBack}>
          Go back
        </button>
      </div>
    </div>
  )
}
