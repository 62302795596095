import type { InteractiveProps } from '@/parts/jester/interactive/types'
import { extractFirstManifestKey, toReadable } from '@/utils/readable'
import { useState } from 'react'

export function MockPassFailActivityFor(saveResultPropertyName: string, initialState: any | null = null, numberOfQuestions = 10) {
  return function MockPassFailActivity({ configuration: { contentActions, manifests } }: InteractiveProps) {
    const { goBack, goNext } = contentActions
    const [result, setResult] = useState<any | null>(initialState)
    const activityName = toReadable(extractFirstManifestKey(manifests))

    const saveResultAndUpdate = async (request: any) => {
      const result = await contentActions[saveResultPropertyName](request)
      setResult(result)
    }
    const saveResultData = {
      correctCount: numberOfQuestions,
      totalCount: numberOfQuestions,
      correctQuestionNumbers: [...Array(numberOfQuestions).keys()].map((n) => n + 1),
    }
    const passQuiz = () =>
      saveResultAndUpdate(saveResultData)
    const failQuiz = () => saveResultAndUpdate({ correctCount: 0, totalCount: numberOfQuestions, correctQuestionNumbers: [] })
    const retryQuiz = () => setResult(null)

    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="flex flex-col gap-3 min-w-72">
          <span data-testid="activity-name" className="font-bold uppercase text-xl">
            {activityName}
          </span>
          {result !== null && (
            <>
              {result.passed || result.autoPassed ? (
                <>
                  <span className="text-center">You earned {result.coinsEarned} coins.</span>
                  <button className="rounded bg-green-700 p-3 text-white" data-testid="go-next" onClick={goNext}>
                    Next
                  </button>
                </>
              ) : (
                <>
                  <span className="text-center">You failed.</span>
                  <button className="rounded bg-gray-700 p-3 text-white" data-testid="try-again" onClick={retryQuiz}>
                    Try Again
                  </button>
                </>
              )}
            </>
          )}

          {result === null && (
            <div data-testid="pass-fail-choices">
              <button className="rounded bg-green-700 p-3 text-white" data-testid="pass" onClick={passQuiz}>
                Pass
              </button>
              <button className="rounded bg-red-700 p-3 text-white" data-testid="fail" onClick={failQuiz}>
                Fail
              </button>
            </div>
          )}

          <button className="rounded bg-gray-500 p-3 text-white" data-testid="go-back" onClick={goBack}>
            Back
          </button>
        </div>
      </div>
    )
  }
}
