import type { ThreadId } from '@/graphql/types'
import type { ActivitiesOverview } from '@/parts/activities/types'
import type { InteractiveProps } from '@/parts/jester/interactive/types'
import type { LessonsVariables } from '@/parts/lessons/interactive'
import { useProgressManager } from '@/parts/progression/manager'
import type { GradeContentActions, GradeContentSpec } from '@blakeelearning/content-specs-mathseeds-prime'
import { useEffect, useState } from 'react'

const extractGradeIds = (threads: ActivitiesOverview) =>
  Object.entries(threads)
    .reduce<number[]>((gradeIds, [_thread, grades]) => {
      grades
        .map(({ gradeId }) => gradeId)
        .forEach((gradeId) => {
          if (gradeIds.indexOf(gradeId) < 0) gradeIds.push(gradeId)
        })
      return gradeIds
    }, [])
    .sort()

export default function MockLessons({
  configuration: {
    contentActions: { goBack, goToGrade: _, goToUnit },
    variables: { initialGrade, studentData, threads },
  },
}: InteractiveProps<GradeContentSpec, GradeContentActions, LessonsVariables>) {
  const [currentGradeId, setCurrentGradeId] = useState(initialGrade)
  const [gradeIds, setGradeIds] = useState<number[]>([])
  const { isLevelAccessible } = useProgressManager()

  const incrementGradeId = () => setCurrentGradeId(Math.min(currentGradeId + 1, Math.max(...gradeIds)))
  const decrementGradeId = () => setCurrentGradeId(Math.max(currentGradeId - 1, Math.min(...gradeIds)))

  useEffect(() => setGradeIds(extractGradeIds(threads)), [threads])

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-xs h-full overflow-scroll">
      <div className="flex flex-col gap-5 items-evenly text-center h-1/2 max-w-full justify-start lg:justify-center">
        <div className="flex gap-5 justify-between items-center flex-col lg:flex-row">
          <button
            className="rounded p-4 bg-white/50 flex flex-col text-xl items-center justify-center hover:bg-white/100"
            data-testid="go-back"
            type="button"
            onClick={() => goBack()}
          >
            Go Back
          </button>
          <div className="flex gap-5 grow justify-center items-start text-xl">
            <div className="flex gap-2 flex-col lg:flex-row">
              <span className="font-bold uppercase" data-testid="woodling">
                Selected Woodling
              </span>
              <span>{studentData.selectedWoodling}</span>
            </div>
            <div className="flex gap-2 flex-col lg:flex-row">
              <span className="font-bold uppercase" data-testid="coins">
                Coins
              </span>
              <span>{studentData.coins}</span>
            </div>
          </div>
        </div>
        <div className="flex gap-3 flex-col lg:flex-row">
          <div className="rounded flex flex-col gap-3 justify-center self-center text-xl p-4 bg-white/25">
            <button
              onClick={incrementGradeId}
              className="rounded basis-1 p-4 bg-white/50 flex flex-col items-center justify-center hover:bg-white/100"
            >
              &#8593;
            </button>
            <span className="font-bold uppercase text-xl">Grade {currentGradeId}</span>
            <button
              onClick={decrementGradeId}
              className="rounded basis-1 p-4 bg-white/50 flex flex-col items-center justify-center hover:bg-white/100"
            >
              &#8595;
            </button>
          </div>
          {Object.entries(threads).map(([threadId, gradeData]) => (
            <div key={threadId} className="rounded flex flex-col gap-2 bg-white/25 p-5">
              <div className="flex flex-col grow justify-evenly gap-2">
                {gradeData.map(({ units: ascendingUnits }) => {
                  const units = ascendingUnits.slice()
                  units.reverse()

                  return units.map(({ unitId, unitName }) => {
                    const [isAccessible] = isLevelAccessible({ threadId: threadId as ThreadId, unitId })
                    return (
                      <button
                        disabled={!isAccessible}
                        data-testid={`go-to-${threadId}-${unitId}`}
                        className="flex flex-col grow items-stretch"
                        type="button"
                        key={unitId}
                        onClick={() => goToUnit({ threadId, unitId })}
                      >
                        <span
                          className={
                            'grow rounded basis-1 p-4 flex flex-col justify-center' +
                            (isAccessible ? ' bg-white/75 hover:bg-white' : ' bg-white/40')
                          }
                        >
                          <span className="font-bold">Unit {unitId}</span>
                          <span>{unitName}</span>
                        </span>
                      </button>
                    )
                  })
                })}
              </div>
              <button
                data-testid={`go-to-${threadId}`}
                className="uppercase font-bold text-xl"
                onClick={() => goToUnit({ threadId })}
              >
                {threadId}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
