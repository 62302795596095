import type { InteractiveProps } from '@/parts/jester/interactive/types'
import { extractFirstManifestKey, toReadable } from '@/utils/readable'
import { useState } from 'react'

export function MockMilestoneActivityFor(saveResultPropertyName: string, initialState: any | null = null) {
  return function MockMilestoneActivity({ configuration: { contentActions, manifests } }: InteractiveProps) {
    const { goBack, goNext } = contentActions
    const [result, setResult] = useState<any | null>(initialState)
    const activityName = toReadable(extractFirstManifestKey(manifests))

    const saveResult = async () => {
      const result = await contentActions[saveResultPropertyName]()
      setResult(result)
    }

    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="flex flex-col gap-3 min-w-72">
          <span data-testid="activity-name" className="font-bold uppercase text-xl">
            {activityName}
          </span>
          {result !== null ? (
            <>
              <span data-testid="coins-earned" className="text-center">
                You earned {result.coinsEarned} coins.
              </span>
              <button className="rounded bg-green-700 p-3 text-white" data-testid="go-next" onClick={goNext}>
                Next
              </button>
            </>
          ) : (
            <>
              <button className="rounded bg-green-700 p-3 text-white" data-testid="save-result" onClick={saveResult}>
                Save Result
              </button>
            </>
          )}

          <button className="rounded bg-gray-500 p-3 text-white" data-testid="go-back" onClick={goBack}>
            Back
          </button>
        </div>
      </div>
    )
  }
}
