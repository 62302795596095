import { MockPassFailActivityFor } from './activity/pass-fail-activity'
import MockFascinatingFact from './fascinating-fact/component'
import MockGame from './game/component'
import MockGamesMenu from './games-menu/component'
import MockLessonMenu from './lesson-menu/component'
import MockLessons from './lessons/component'
import MockMainNavigation from './main-navigation/component'
import MockShop from './shop/component'
import { MockMilestoneActivityFor } from './activity/milestone-activity'
import MockFinished from './finished/component'

const MockComponents = [
  {
    url: 'main-nav',
    component: MockMainNavigation,
  },
  {
    url: 'lesson-menu',
    component: MockLessonMenu,
  },
  {
    url: 'shop',
    component: MockShop,
  },
  {
    url: 'my-lessons',
    component: MockLessons,
  },
  {
    url: 'games-menu',
    component: MockGamesMenu,
  },
  {
    url: 'games',
    component: MockGame,
  },
  {
    key: 'fascinating-fact',
    component: MockFascinatingFact,
  },
  {
    key: 'checkup-quiz',
    component: MockPassFailActivityFor('saveCheckupQuizResult'),
  },
  {
    key: 'end-of-grade-skills-quiz',
    component: MockPassFailActivityFor('saveEndOfGradeSkillsQuizResult', null, 20),
  },
  {
    key: 'end-of-grade-application-quiz',
    component: MockPassFailActivityFor('saveEndOfGradeApplicationQuizResult'),
  },
  {
    key: 'end-of-lesson-quiz',
    component: MockPassFailActivityFor('saveEndOfLessonQuizResult'),
  },
  {
    key: 'end-of-unit-quiz',
    component: MockPassFailActivityFor('saveEndOfUnitQuizResult'),
  },
  {
    key: 'interactive',
    component: MockPassFailActivityFor('saveInteractiveActivityResult'),
  },
  {
    key: 'review',
    component: MockPassFailActivityFor('saveReviewSkillsResult'),
  },
  {
    key: 'problem-solving',
    component: MockMilestoneActivityFor('saveProblemSolvingActivityResult'),
  },
  {
    key: 'teaching-video',
    component: MockMilestoneActivityFor('saveTeachingVideoResult'),
  },
  {
    url: 'finished',
    component: MockFinished,
  },
]

export default MockComponents
