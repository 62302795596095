import type { InteractiveProps } from '@/parts/jester/interactive/types'
import type { ShopVariables } from '@/parts/shop/interactive'
import type { ContentActions } from '@blakeelearning/content-loader-render'
import type { ShopContentSpec } from '@blakeelearning/content-specs-mathseeds-prime'
import { useState } from 'react'

export default function MockShop({
  configuration: {
    contentActions,
    variables: {
      avatars: [firstAvatar, ...otherAvatars],
      coins,
      purchases,
      selectedAvatar,
    },
  },
}: InteractiveProps<ShopContentSpec, ContentActions<ShopContentSpec>, ShopVariables>) {
  const [purchasedAvatarIds, setPurchasedAvatarIds] = useState(
    purchases.filter((p) => p.category === 'avatar').map((p) => p.item),
  )
  const [selectedAvatarId, setSelectedAvatarId] = useState(firstAvatar.id)
  const [coinBalance, setCoinBalance] = useState(coins)
  const [activatedAvatarId, setActivatedAvatarId] = useState(selectedAvatar)

  const purchaseAvatar = async ({ id }: { id: string }) => {
    let result

    try {
      result = await contentActions.purchaseAvatar({ id })
    } catch (error) {
      console.log(error)
    }

    const newBalance = result?.coins as number
    const newPurchasedAvatarList = result?.purchases as { item: string }[]
    const newSelectedAvatar = result?.selectedAvatar as string

    if (newBalance !== undefined) {
      setCoinBalance(newBalance)
    }

    if (newPurchasedAvatarList) {
      setPurchasedAvatarIds(newPurchasedAvatarList.map((p) => p.item))
    }

    if (newSelectedAvatar) {
      setActivatedAvatarId(newSelectedAvatar)
    }
  }

  const selectAvatar = async ({ id }: { id: string }) => {
    let result
    try {
      result = await contentActions.selectAvatar({ id })
    } catch (error) {
      console.log(error)
    }

    const newSelectedAvatar = result?.selectedAvatar as string
    if (newSelectedAvatar) {
      setActivatedAvatarId(newSelectedAvatar)
    }
  }

  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center">
      <div className="flex flex-col items-center">
        <span data-test-coins-count><b>{coinBalance}</b> coins to spend</span>
      </div>
      <div className="flex flex-col items-center">
        <span data-test-activated-woodling>Activated woodling is <b>{activatedAvatarId}</b></span>
      </div>
      <div className="flex flex-row flex-wrap justify-center">
        {[firstAvatar, ...otherAvatars].map((avatar) => {
          const classes = []
          classes.push(avatar.id === selectedAvatarId ? 'border-white/75' : 'border-white/25')
          if (purchasedAvatarIds.find((id) => id === avatar.id)) {
            classes.push('bg-blue-500/25')
          }
          const classNames =
            'border-2 rounded flex flex-col justify-center items-center basis-1/4 p-2 m-2 ' + classes.join(' ')

          return (
            <button
              type="button"
              key={avatar.id}
              data-testid={avatar.id}
              className={classNames}
              onClick={() => setSelectedAvatarId(avatar.id)}
            >
              <span className="font-bold uppercase">{avatar.id}</span>
              <span>{avatar.price} coins</span>
            </button>
          )
        })}
      </div>
      <div className="flex flex-col text-center w-1/3">
        <button
          type="button"
          data-test-select-woodling
          className="border rounded border-black p-2 my-1"
          onClick={() => selectAvatar({ id: selectedAvatarId })}
          disabled={!selectedAvatarId}
        >
          Select Woodling
        </button>
        <button
          type="button"
          data-test-purchase-woodling
          className="border rounded border-black p-2 my-1"
          onClick={() => purchaseAvatar({ id: selectedAvatarId })}
          disabled={!selectedAvatarId}
        >
          Purchase Woodling
        </button>
        <button
          type="button"
          data-test-go-back
          className="border rounded border-black p-2 my-1"
          onClick={() => contentActions.goBack()}
        >
          Go Back
        </button>
      </div>
    </div>
  )
}
